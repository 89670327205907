import { EN, ID } from '../shared/constants/locale';

// HEADER
export const ALLOTMENT_AND_RATE = {
  [EN]: 'Allotment and Rate',
  [ID]: 'Slot & Daftar Harga',
};

export const INVENTORY = {
  [EN]: 'Inventory',
  [ID]: 'Inventori',
};

export const ALLOTMENT = {
  [EN]: 'Allotment',
  [ID]: 'Alokasi',
};

export const RATE = {
  [EN]: 'Rate',
  [ID]: 'Harga',
};

export const ALLOTMENT_AVAILABILITY = {
  [EN]: 'Allotment Availability',
  [ID]: 'Ketersediaan Alokasi',
};

export const ALLOTMENT_AND_FARE = {
  [EN]: 'Allotment and Fare',
  [ID]: 'Alokasi Mobil dan Biaya',
};

export const AUTHORIZED_AIRPORT = {
  [EN]: 'Authorized Airport',
  [ID]: 'Authorized Airport',
};

export const FARE = {
  [EN]: 'Fare',
  [ID]: 'Tarif',
};

export const INVENTORY_MONITORING = {
  [EN]: 'Inventory Monitoring',
  [ID]: 'Monitor Inventori',
};

export const PREBUY = {
  [EN]: 'Prebuy',
  [ID]: 'Prebuy',
};

export const SLOT_AND_PRICE = {
  [EN]: 'Slot & Price',
  [ID]: 'Inventori',
};

export const PICKUP_MANAGEMENT = {
  [EN]: 'Pickup Management',
  [ID]: 'Pengaturan Penjemputan',
};

export const BOOKING_MANAGEMENT = {
  [EN]: 'Booking Management',
  [ID]: 'Pengaturan Pesanan',
};

export const BOOKING_TIME = {
  [EN]: 'Booking Time',
  [ID]: 'Waktu Pemesanan',
};

export const RESCHEDULED_PICKUP_TIME = {
  [EN]: 'Rescheduled Pickup Time',
  [ID]: 'Penggantian Waktu Penjemputan',
};

export const SUPPLIER_DATA = {
  [EN]: 'Supplier Data',
  [ID]: 'Data Supplier',
};

export const SUPPLIER_CITY_PRODUCT = {
  [EN]: 'SKU',
  [ID]: 'SKU',
};

export const SUPPLIER_CITY_DATA = {
  [EN]: 'Supplier City Data',
  [ID]: 'Data Kota Supplier',
};

export const CITY_SETTING = {
  [EN]: 'City Setting',
  [ID]: 'Pengaturan Kota',
};

export const ADD_NEW_INVENTORY = {
  [EN]: 'Add New Inventory',
  [ID]: 'Tambah Inventori Baru',
};

export const PARTNER_CHANGES = {
  [EN]: 'Partner Changes',
  [ID]: 'Perubahan Supplier',
};

export const CITY_DETAILS = {
  [EN]: 'City Details',
  [ID]: 'Kota',
};

export const BOOKING = {
  [EN]: 'Booking',
  [ID]: 'Pemesanan',
};

export const BOOKING_MANIFEST = {
  [EN]: 'Booking Manifest',
  [ID]: 'Daftar Pesanan',
};

export const REPORTING = {
  [EN]: 'Reporting',
  [ID]: 'Laporan',
};

export const BOOKING_MANIFEST_SUMMARY = {
  [EN]: 'Booking Manifest Summary',
  [ID]: 'Ringkasan Pesanan',
};

export const FAILED_PAYMENTS = {
  [EN]: 'Failed Payments',
  [ID]: 'Pembayaran yang Gagal',
};

export const TOLL = {
  [EN]: 'Toll',
  [ID]: 'Toll',
};

export const SEASON = {
  [EN]: 'Season',
  [ID]: 'Season',
};

export const PREDEP_NOTIFICATION = {
  [EN]: 'Predep Notification',
  [ID]: 'Notifikasi Predep',
};

export const PREDEP_TIME = {
  [EN]: 'Predep Time',
  [ID]: 'Waktu Predep',
};

export const PREDEP_TIMESPAN = {
  [EN]: 'Predep Timespan',
  [ID]: 'Periode Predep',
};

export const LOGOUT = {
  [EN]: 'Logout',
  [ID]: 'Keluar',
};
// HEADER

export const ALL = {
  [EN]: 'All',
  [ID]: 'Semua',
};

export const NAME = {
  [EN]: 'Name',
  [ID]: 'Nama',
};

export const PHONE_NUMBER = {
  [EN]: 'Phone number',
  [ID]: 'Nomor telepon',
};

export const SUPPLIER_NAME = {
  [EN]: 'Supplier Name',
  [ID]: 'Nama Supplier',
};

export const EMAIL = {
  [EN]: 'Email',
  [ID]: 'Email',
};

export const TERMINAL_NAME = {
  [EN]: 'Terminal Name',
  [ID]: 'Nama Terminal',
};

export const BOOKING_STATUS = {
  [EN]: 'Booking Status',
  [ID]: 'Status Pemesanan',
};

export const SUPPLIER = {
  [EN]: 'Supplier',
  [ID]: 'Supplier',
};

export const SERVICE_CITY_OR_REGION = {
  [EN]: 'City / Region',
  [ID]: 'Kota / Wilayah',
};

export const CITY_OR_REGION = {
  [EN]: 'City / Region',
  [ID]: 'Kota / Provinsi',
};

export const HEADQUARTER = {
  [EN]: 'Headquarter City / Region',
  [ID]: 'Kantor Pusat',
};

export const BRANCH_CITY = {
  [EN]: 'Branch City / Region',
  [ID]: 'Kantor Cabang',
};

export const CAR_PLATE = {
  [EN]: 'Car Plate',
  [ID]: 'Pelat Mobil',
};

export const CAR_NAME = {
  [EN]: 'Car Name',
  [ID]: 'Nama Mobil',
};

export const SUPPLIER_TYPE = {
  [EN]: 'Supplier Type',
  [ID]: 'Tipe Supplier',
};

export const MARKETING_MANAGER = {
  [EN]: 'Marketing Manager',
  [ID]: 'Marketing Manager',
};

export const CAR_TYPE = {
  [EN]: 'Car Type',
  [ID]: 'Tipe Mobil',
};

export const DATE_RANGE = {
  [EN]: 'Date Range',
  [ID]: 'Rentang Tanggal',
};

export const RENTAL_DATE = {
  [EN]: 'Rental Date',
  [ID]: 'Tanggal Rental',
};

export const PICKUP_DATE = {
  [EN]: 'Pickup Date',
  [ID]: 'Tanggal Jemput',
};

export const SORT_BY = {
  [EN]: 'Sort by',
  [ID]: 'Urut berdasarkan',
};

export const SORT_TYPE = {
  [EN]: 'Sort Type',
  [ID]: 'Jenis pengurutan',
};

export const PICKUP_TIME = {
  [EN]: 'Pickup Time',
  [ID]: 'Waktu Jemput',
};

export const PICKUP_DATE_TIME = {
  [EN]: 'Pickup Date & Time',
  [ID]: 'Tanggal & Waktu Penjemputan',
};

export const INVOICE_AMOUNT = {
  [EN]: 'Invoice Amount',
  [ID]: 'Total Invoice',
};

export const ADDITIONAL_CHARGE = {
  [EN]: 'Additional Charge',
  [ID]: 'Biaya Tambahan',
};

export const PUBLISH_RATE = {
  [EN]: 'Publish Rate',
  [ID]: 'Harga Jual',
};

export const BOOKING_DATE = {
  [EN]: 'Booking Date',
  [ID]: 'Tanggal Pesan',
};

export const PRICE = {
  [EN]: 'Price',
  [ID]: 'Harga',
};

export const QUANTITY = {
  [EN]: 'Quantity',
  [ID]: 'Kuantitas',
};

export const VALIDITY_DATE = {
  [EN]: 'Validity Date',
  [ID]: 'Tanggal Berlaku',
};

export const DRIVER_TYPE = {
  [EN]: 'With Driver / Without Driver',
  [ID]: 'Dengan / Tanpa Supir',
};

export const DRIVER_TYPE_LABEL = {
  [EN]: 'Driver Type',
  [ID]: 'Tipe Supir',
};

export const TRANSMISSION = {
  [EN]: 'Transmission',
  [ID]: 'Transmisi',
};

export const DAY_OF_WEEK = {
  [EN]: 'Days',
  [ID]: 'Hari',
};

export const SEARCH = {
  [EN]: 'Search',
  [ID]: 'Cari',
};

export const RESET = {
  [EN]: 'Reset',
  [ID]: 'Reset',
};

export const ADD = {
  [EN]: 'Add',
  [ID]: 'Tambah',
};

export const SUBTRACT = {
  [EN]: 'Subtract',
  [ID]: 'Kurang',
};

export const CANCEL = {
  [EN]: 'Cancel',
  [ID]: 'Batal',
};

export const CONFIRM = {
  [EN]: 'Confirm',
  [ID]: 'Konfirmasi',
};

export const DATE = {
  [EN]: 'Date',
  [ID]: 'Tanggal',
};

export const PERIOD = {
  [EN]: 'Period',
  [ID]: 'Periode',
};

export const RANK = {
  [EN]: 'Rank',
  [ID]: 'Peringkat',
};

export const PHOTO = {
  [EN]: 'Photo',
  [ID]: 'Foto',
};

export const ELIGIBLE = {
  [EN]: 'Eligible',
  [ID]: 'Tidak memenuhi syarat',
};

export const NOT_ELIGIBLE = {
  [EN]: 'Not eligible',
  [ID]: 'Tidak memenuhi syarat',
};

export const LAST_UPDATE = {
  [EN]: 'Last update',
  [ID]: 'Terakhir diperbarui',
};

export const ACTION_BY = {
  [EN]: 'Action By',
  [ID]: 'Tindakan Oleh',
};

export const CHANGE_DATE = {
  [EN]: 'Change Date',
  [ID]: 'Tanggal Perubahan',
};

export const CHANGE_BY = {
  [EN]: 'Change By',
  [ID]: 'Diubah Oleh',
};

export const VEHICLE_INVENTORY = {
  [EN]: 'Vehicle Inventory',
  [ID]: 'Inventori Mobil',
};

export const VEHICLE_NAME = {
  [EN]: 'Vehicle name',
  [ID]: 'Nama Kendaraan',
};

export const SUPPLIER_OPERATION_PICKUP_LANE = {
  [EN]: 'Supplier Pick up Configuration',
  [ID]: 'Supplier Pick up Configuration', // TODO add translation
};

export const PICKUP_LANE_CONFIG = {
  [EN]: 'Pick up Lane Configuration',
  [ID]: 'Pick up Lane Configuration', // TODO add translation
};

export const ALLOTMENT_DATE = {
  [EN]: 'Allotment Date',
  [ID]: 'Tanggal Slot',
};

export const PREVIOUS_VALUE = {
  [EN]: 'Previous',
  [ID]: 'Sebelum',
};

export const NEW_VALUE = {
  [EN]: 'New',
  [ID]: 'Setelah',
};

export const CHANGE_OBJECT = {
  [EN]: 'Changes',
  [ID]: 'Perubahan',
};

export const CHANGE = {
  [EN]: 'Change',
  [ID]: 'Ubah',
};

export const EDIT = {
  [EN]: 'Edit',
  [ID]: 'Ubah',
};

export const MODIFY = {
  [EN]: 'Modify',
  [ID]: 'Ganti',
};

export const UPDATE = {
  [EN]: 'Update',
  [ID]: 'Perbarui',
};

export const SAVE = {
  [EN]: 'Save',
  [ID]: 'Simpan',
};

export const SAVE_ALL = {
  [EN]: 'Save All',
  [ID]: 'Simpan Semua',
};

export const APPLY = {
  [EN]: 'Apply',
  [ID]: 'Terapkan',
};

export const BACK = {
  [EN]: 'Back',
  [ID]: 'Kembali',
};

export const STATUS = {
  [EN]: 'Status',
  [ID]: 'Status',
};

export const ALL_STATUS = {
  [EN]: 'All Status',
  [ID]: 'Semua Status',
};

export const START_DATE_AND_TIME = {
  [EN]: 'Start Date & Time',
  [ID]: 'Tanggal & Waktu Mulai',
};

export const START_DATE = {
  [EN]: 'Start Date',
  [ID]: 'Tanggal Mulai',
};

export const START_TIME = {
  [EN]: 'Start Time',
  [ID]: 'Waktu Mulai',
};

export const END_DATE = {
  [EN]: 'End Date',
  [ID]: 'Tanggal Berakhir',
};

export const END_DATE_AND_TIME = {
  [EN]: 'End Date & Time',
  [ID]: 'Tanggal & Waktu Berakhir',
};

export const START_TIME_MUST_BE_SMALLER_VALIDATION_MESSAGE = {
  [EN]: 'Start time must be smaller than end time',
  [ID]: 'Waktu mulai harus lebih kecil dari waktu selesai',
};

export const ACTION = {
  [EN]: 'Action',
  [ID]: 'Tindakan',
};

export const END_TIME = {
  [EN]: 'End Time',
  [ID]: 'Waktu Berakhir',
};

export const SEE_DETAILS = {
  [EN]: 'View Details',
  [ID]: 'Lihat Detail',
};

export const DETAIL = {
  [EN]: 'Detail',
  [ID]: 'Detail',
};

export const SELLING_INFORMATION = {
  [EN]: 'Selling Information',
  [ID]: 'Informasi Penjualan',
};

export const NOTES = {
  [EN]: 'Notes',
  [ID]: 'Catatan',
};

export const IS_BOOKING_DETAIL_REQUIRED = {
  [EN]: 'Is Booking Detail Required',
  [ID]: 'Perlu Mengisi Detail Booking',
};

export const SEE_AVAILABILITY = {
  [EN]: 'See Availability',
  [ID]: 'Lihat Ketersediaan',
};

export const VIEW_SERVICE_CITY = {
  [EN]: 'View Service City',
  [ID]: 'Lihat Kota',
};

export const ADD_NEW_SUPPLIER = {
  [EN]: 'Add New Supplier',
  [ID]: 'Tambah Supplier Baru',
};

export const ADD_SUPPLIER = {
  [EN]: 'Add Supplier',
  [ID]: 'Tambah Supplier',
};

export const EDIT_SUPPLIER = {
  [EN]: 'Edit Supplier',
  [ID]: 'Edit Supplier',
};

export const ENABLED = {
  [EN]: 'Enabled',
  [ID]: 'Aktif',
};

export const DISABLED = {
  [EN]: 'Disabled',
  [ID]: 'Tidak Aktif',
};

export const WITH_DRIVER = {
  [EN]: 'With Driver',
  [ID]: 'Dengan Supir',
};

export const WITHOUT_DRIVER = {
  [EN]: 'Without Driver',
  [ID]: 'Tanpa Supir',
};

export const SUCCESS = {
  [EN]: 'Success',
  [ID]: 'Sukses',
};

export const BOOKED = {
  [EN]: 'Booked',
  [ID]: 'Telah Dipesan',
};

export const ISSUED = {
  [EN]: 'Issued',
  [ID]: 'Telah Dikonfirmasi',
};

export const ISSUED_WAITING_FOR_ACKNOWLEDGE = {
  [EN]: 'Issued - Waiting for confirmation',
  [ID]: 'Voucher Terbit - Menunggu Konfirmasi',
};

export const ISSUED_ACKNOWLEDGED = {
  [EN]: 'Issued - Confirmed',
  [ID]: 'Voucher Terbit - Pesanan Diterima',
};

export const ISSUED_DRIVER_ASSIGNED = {
  [EN]: 'Issued - Driver Assigned',
  [ID]: 'Voucher Terbit - Supir ditugaskan',
};

export const ISSUED_REJECTED = {
  [EN]: 'Issued - Rejected',
  [ID]: 'Voucher Terbit - Ditolak',
};

export const CANCELLED = {
  [EN]: 'Cancelled',
  [ID]: 'Dibatalkan',
};

export const EXPIRED = {
  [EN]: 'Expired',
  [ID]: 'Kedaluwarsa',
};

export const REFUND = {
  [EN]: 'With Refund',
  [ID]: 'Dengan Refund',
};

export const NO_REFUND = {
  [EN]: 'No Refund',
  [ID]: 'Tanpa Refund',
};

export const APPROVED = {
  [EN]: 'Approved',
  [ID]: 'Disetujui',
};

export const REJECTED = {
  [EN]: 'Rejected',
  [ID]: 'Ditolak',
};

export const PENDING = {
  [EN]: 'Pending',
  [ID]: 'Menunggu Keputusan',
};

export const ACTIVE = {
  [EN]: 'Active',
  [ID]: 'Aktif',
};

export const INACTIVE = {
  [EN]: 'Inactive',
  [ID]: 'Tidak Aktif',
};

export const INSTANT_BOOKING_WITHOUT_QUEUE = {
  [EN]: 'Instant Booking Without Queue',
  [ID]: 'Pesanan Instan Tanpa Antrian',
};

export const SUSPENDED = {
  [EN]: 'Suspended',
  [ID]: 'Ditunda',
};

export const REVERSED = {
  [EN]: 'Reversed',
  [ID]: 'Dikembalikan',
};

export const ALTERED = {
  [EN]: 'Altered',
  [ID]: 'Diubah',
};

export const EXTENDED = {
  [EN]: 'Extended',
  [ID]: 'Diperpanjang',
};

export const YES = {
  [EN]: 'Yes',
  [ID]: 'Ya',
};

export const NO = {
  [EN]: 'No',
  [ID]: 'Tidak',
};

// Notification
export const UPDATE_SUCCESS = {
  [EN]: 'Update successful',
  [ID]: 'Update berhasil',
};

// Form validation message
export const SUPPLIER_NAME_VALIDATION_MESSAGE = {
  [EN]: 'Please select supplier',
  [ID]: 'Mohon pilih supplier',
};

export const CITY_OR_REGION_VALIDATION_MESSAGE = {
  [EN]: 'Please select city/region',
  [ID]: 'Mohon pilih kota/wilayah',
};

export const CAR_TYPE_VALIDATION_MESSAGE = {
  [EN]: 'Please select car type',
  [ID]: 'Mohon pilih tipe mobil',
};

export const POOL_VALIDATION_MESSAGE = {
  [EN]: 'Please select pool',
  [ID]: 'Mohon pilih pool',
};

export const TRANSMISSION_VALIDATION_MESSAGE = {
  [EN]: 'Please select transmission',
  [ID]: 'Mohon pilih transmisi',
};

export const SELECTED_TRANSMISSION_NOT_AVAILABLE = {
  [EN]: 'The selected transmission is not available',
  [ID]: 'Transmisi yang dipilih tidak tersedia',
};

export const STANDARD_CONFIRMATION_MESSAGE = {
  [EN]: 'Are you sure?',
  [ID]: 'Apakah Anda yakin?',
};

export const NO_RESULT = {
  [EN]: 'Search result is empty. Please modify your search.',
  [ID]: 'Daftar pencarian tidak ditemukan. Mohon ganti pencarian Anda.',
};

export const INPUT_NUMBER_ONLY = {
  [EN]: 'Input must be number only',
  [ID]: 'Input hanya boleh angka',
};

export const CHANGE_TO_OLDER_VERSION = {
  [EN]: 'Change to older version',
  [ID]: 'Ganti ke versi lama',
};

export const CHANGE_TO_NEWER_VERSION = {
  [EN]: 'Change to newer version',
  [ID]: 'Ganti ke versi baru',
};

export const CHANGE_PASSWORD = {
  [EN]: 'Change Password',
  [ID]: 'Ganti Password',
};

export const CHANGE_MODE = {
  [EN]: 'Change Mode',
  [ID]: 'Ganti Mode',
};

export const TRIPS = {
  [EN]: 'Trips',
  [ID]: 'Perjalanan',
};

export const TODAY = {
  [EN]: 'Today',
  [ID]: 'Hari ini',
};

export const TOMORROW = {
  [EN]: 'Tomorrow',
  [ID]: 'Besok',
};

export const THIS_WEEK = {
  [EN]: 'This Week',
  [ID]: 'Minggu ini',
};

export const THIS_MONTH = {
  [EN]: 'This Month',
  [ID]: 'Bulan ini',
};

export const ALL_RENTAL_PARTNERS = {
  [EN]: 'All Rental Partners',
  [ID]: 'Semua Partner Rental',
};

export const ALL_SERVICE_CITIES = {
  [EN]: 'All Service Cities',
  [ID]: 'Semua Kota Layanan',
};

export const PREVIOUS = {
  [EN]: 'Previous',
  [ID]: 'Sebelumnya',
};

export const NEXT = {
  [EN]: 'Next',
  [ID]: 'Berikutnya',
};

export const DRIVER = {
  [EN]: 'Driver',
  [ID]: 'Sopir',
};

export const HOUR = {
  [EN]: 'Hour',
  [ID]: 'Jam',
};

export const HOURS = {
  [EN]: 'Hours',
  [ID]: 'Jam',
};

export const MINUTE = {
  [EN]: 'Minute',
  [ID]: 'Menit',
};

export const MASTER_DATA = {
  [EN]: 'Master Data',
  [ID]: 'Data Utama',
};

export const PICKUP_LOCATION = {
  [EN]: 'Pick up location',
  [ID]: 'Pick up location', // TODO add translation
};

export const ASSIGNMENT_METHOD = {
  [EN]: 'Assignment Method',
  [ID]: 'Assignment Method', // TODO add translation
};

export const PICKUP_PROCEDURE = {
  [EN]: 'Pickup Procedure',
  [ID]: 'Pickup Procedure', // TODO add translation
};

export const ADD_PICKUP_CONFIGURATION = {
  [EN]: 'Add Pick up Configuration',
  [ID]: 'Add Pick up Configuration', // TODO add translation
};

export const COUNTRY = {
  [EN]: 'Country',
  [ID]: 'Negara',
};

export const LANGUAGE = {
  [EN]: 'Language',
  [ID]: 'Bahasa',
};

export const AIRPORT = {
  [EN]: 'Airport',
  [ID]: 'Bandara',
};

export const CARPOOL_NAME = {
  [EN]: 'Car Pool Name',
  [ID]: 'Nama Pool',
};

export const CAPACITY = {
  [EN]: 'Capacity',
  [ID]: 'Kapasitas',
};

export const ADMIN = {
  [EN]: 'Admin',
  [ID]: 'Admin',
};

export const REDEEM_TICKET = {
  [EN]: 'Redeem Ticket',
  [ID]: 'Redeem Tiket',
};
export const ADD_DESCRIPTION = {
  [EN]: 'Add Description',
  [ID]: 'Tambah Deskripsi',
};

export const ADD_IMAGE = {
  [EN]: 'Add Image',
  [ID]: 'Tambah Gambar',
};

export const DESCRIPTION = {
  [EN]: 'Description',
  [ID]: 'Deskripsi',
};

export const IMAGE = {
  [EN]: 'Image',
  [ID]: 'Gambar',
};

export const ADDITIONAL_IMAGE = {
  [EN]: 'Additional Image',
  [ID]: 'Gambar Tambahan',
};

export const PRIMARY_IMAGE = {
  [EN]: 'Primary Image',
  [ID]: 'Gambar Utama',
};

export const IMAGE_IS_REQUIRED = {
  [EN]: 'Image is required',
  [ID]: 'Perlu melampirkan gambar',
};

export const ADD_CURRENCY = {
  [EN]: 'Add Currency',
  [ID]: 'Tambah Mata Uang',
};

export const RESET_PASSWORD = {
  [EN]: 'Reset Password',
  [ID]: 'Perbarui Kata Sandi',
};

export const DOCUMENT = {
  [EN]: 'Document',
  [ID]: 'Dokumen',
};

export const TO_DATE_RANGE = {
  [EN]: 'To',
  [ID]: 'Sampai',
};

export const ASCENDING_LABEL = {
  [EN]: 'Oldest to Newest',
  [ID]: 'Terlama sampai Terbaru',
};

export const DESCENDING_LABEL = {
  [EN]: 'Newest to Oldest',
  [ID]: 'Terbaru sampai Terlama',
};

export const ROUTE = {
  [EN]: 'Route',
  [ID]: 'Rute',
};
export const ROUTE_ZONAL = {
  [EN]: 'Route zone',
  [ID]: 'Zona rute',
};

export const SKU_CODE = {
  [EN]: 'SKU Code',
  [ID]: 'Kode SKU',
};

export const INVENTORY_TYPE = {
  [EN]: 'Inventory Type',
  [ID]: 'Tipe Inventori',
};

export const TRIP_INCLUSION_TYPE = {
  [EN]: 'Trip Inclusion Type',
  [ID]: 'Tipe Inklusi Perjalanan',
};

export const PRICING_TYPE = {
  [EN]: 'Pricing Type',
  [ID]: 'Tipe Harga',
};

export const HOLD_BOOKING_DURATION = {
  [EN]: 'Hold Booking Duration',
  [ID]: 'Durasi Penahanan Pemesanan',
};

export const GASOLINE_INCLUDED = {
  [EN]: 'Gasoline Included',
  [ID]: 'Termasuk Bensin',
};

export const PARKING_FEE_INCLUDED = {
  [EN]: 'Parking Fee Included',
  [ID]: 'Termasuk Biaya Parkir',
};

export const TOLL_FEE_INCLUDED = {
  [EN]: 'Toll Fee Included',
  [ID]: 'Termasuk Biaya Toll',
};

export const SHORT_DESCRIPTION = {
  [EN]: 'Short Description',
  [ID]: 'Deskripsi Singkat',
};

export const ADD_SHORT_DESCRIPTION = {
  [EN]: 'Add Short Description',
  [ID]: 'Tambah Deskripsi Singkat',
};

export const METER_BASED = {
  [EN]: 'Meter Based',
  [ID]: 'Basis Meter',
};

export const ZONE_BASED = {
  [EN]: 'Zone Based',
  [ID]: 'Basis Zona',
};

export const INSTANT_BOOKING_SETTINGS = {
  [EN]: 'Instant Booking Settings',
  [ID]: 'Pengaturan Pesanan Instan',
};

export const NOT_AVAILABLE = {
  [EN]: 'Not Available',
  [ID]: 'Tidak Tersedia',
};

export const AGO = {
  [EN]: 'ago',
  [ID]: 'yang lalu',
};

export const FEW = {
  [EN]: 'few',
  [ID]: 'beberapa',
};

export const JUST_NOW = {
  [EN]: 'Just Now',
  [ID]: 'Baru Saja',
};
export const LEAD_TIME_REGULAR = {
  [EN]: 'Lead Time Regular',
  [ID]: 'Lead Time Regular',
};

export const LEAD_TIME_SEASONAL = {
  [EN]: 'Lead Time Seasonal',
  [ID]: 'Lead Time Seasonal',
};

export const LEAD_TIME_INTERCITY = {
  [EN]: 'Lead Time Intercity',
  [ID]: 'Lead Time Intercity',
};

export const SELECT = {
  [EN]: 'Select',
  [ID]: 'Pilih',
};

export const FILL = {
  [EN]: 'Fill',
  [ID]: 'Isi',
};

export const CLEAR = {
  [EN]: 'Clear',
  [ID]: 'Bersihkan',
};

export const DELETE = {
  [EN]: 'Delete',
  [ID]: 'Hapus',
};

export const MERGE = {
  [EN]: 'Merge',
  [ID]: 'Gabung',
};

export const NUMBER_LABEL = {
  [EN]: 'No.',
  [ID]: 'No.',
};

export const DAYS_OF_WEEK = {
  [EN]: 'Days of Week',
  [ID]: 'Hari-Hari dalam Seminggu',
};

export const DAYS_OF_WEEK_VALIDATION_MESSAGE = {
  [EN]: (numOfDay = 1) => `At least ${numOfDay} day(s) must be choosen`,
  [ID]: (numOfDay = 1) => `Setidaknya harus memilih ${numOfDay} hari`,
};

export const SERVICE_CLASS = {
  [EN]: 'Service Class',
  [ID]: 'Kelas Layanan',
};

export const SURCHARGE_BY_SEARCH_TIME = {
  [EN]: 'Surcharge by Search Time',
  [ID]: 'Biaya Tambahan berdasarkan Waktu Pencarian',
};

export const PIC = {
  [EN]: 'PIC',
  [ID]: 'PIC',
};

export const CUTOFF_TYPE = {
  [EN]: 'Cutoff Type',
  [ID]: 'Cutoff Type',
};

export const CUTOFF_TYPE_VALIDATION_MESSAGE = {
  [EN]: 'Please select Cutoff Type',
  [ID]: 'Mohon pilih Cutoff Type',
};
export const CUTOFF_TYPE_END_OF_DAY = {
  [EN]: 'End of Day',
  [ID]: 'End of Day',
};

export const CUTOFF_TYPE_TWENTY_FOUR_HOURS = {
  [EN]: '24 Hours',
  [ID]: '24 Hours',
};

export const CHANGE_TYPE = {
  [EN]: 'Change type',
  [ID]: 'Tipe perubahan',
};
export const LOAD_MORE = {
  [EN]: 'Load more',
  [ID]: 'Muat lebih banyak',
};
